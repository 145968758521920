import request from "../utils/request";

export function get_role_list(data: JSON){
    return request({
        url: "/getRoleList",
        method: "post",
        data: data
    });
}

export function get_role_detail(data: JSON){
    return request({
        url: "/getRoleDetail",
        method: "post",
        data: data
    });
}

export function get_role_option(data: JSON){
    return request({
        url: "/data/getRoleOption",
        method: "post",
        data: data
    });
}

export function save_role_detail(data: JSON){
    return request({
        url: "/saveRoleDetail",
        method: "post",
        data: data
    });
}

export function delete_role(data: JSON){
    return request({
        url: "/deleteRole",
        method: "post",
        data: data
    });
}