<template>
    <div class="align-items-start p-3 text-start">
        <nav aria-label="breadcrumb">
        <ol class="breadcrumb text-dark m-auto">
            <li class="breadcrumb-item active" aria-current="page">權限管理</li>
            <li class="breadcrumb-item active" aria-current="page">群組管理</li>
            <li class="breadcrumb-item active" aria-current="page">群組權限設定</li>
        </ol>
        </nav>
        <hr class="w-100">
        <Form @submit="save()" :validation-schema="schema" @invalid-submit="on_invalid_submit">
            <div class="col-12 row mx-auto data-form mb-3">
                <div class="mb-3">
                    <label for="role_name" class="form-label"><span class="required" v-if="$route.name !== 'RoleView'">*</span>群組名稱</label>
                    <Field name="role_name" v-slot="{ meta, field }" v-model="role_data.name">
                        <input
                            id="role_name"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            :disabled="$route.name === 'RoleView'"
                        />
                    </Field>
                    <ErrorMessage name="role_name" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label class="form-label"><span class="required" v-if="$route.name !== 'RoleView'">*</span>啟用狀態</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <Field name="status_options" v-slot="{ meta, field }" v-model="role_data.status">
                                <input
                                    id="status_radio_1"
                                    v-bind="field"
                                    class="form-check-input"
                                    :class="{
                                        'is-valid': meta.valid && meta.touched,
                                        'is-invalid': !meta.valid && meta.touched,
                                    }"
                                    type="radio"
                                    :value="1"
                                    :checked="role_data.status === 1"
                                    :disabled="$route.name === 'RoleView'"
                                />
                            </Field>
                            <label class="form-check-label" for="status_radio_1">啟用</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <Field name="status_options" v-slot="{ meta, field }" v-model="role_data.status">
                                <input
                                    id="status_radio_2"
                                    v-bind="field"
                                    class="form-check-input"
                                    :class="{
                                        'is-valid': meta.valid && meta.touched,
                                        'is-invalid': !meta.valid && meta.touched,
                                    }"
                                    type="radio"
                                    :value="0"
                                    :checked="role_data.status === 0"
                                    :disabled="$route.name === 'RoleView'"
                                />
                            </Field>
                            <label class="form-check-label" for="status_radio_2">停用</label>
                        </div>
                    </div>
                    <ErrorMessage name="status_options" class="radio-invalid-feedback"/>
                </div>
            </div>
            <div class="col-12 row mx-auto">
                <div class="table-responsive container-lg col-12">
                    <table class="table table-striped table-hover text-center align-middle">
                        <thead class="table-dark">
                            <tr>
                                <th class="col-2">主選單</th>
                                <th class="col-2">副選單</th>
                                <th class="col-1">檢視</th>
                                <th class="col-1">新增</th>
                                <th class="col-1">編輯</th>
                                <th class="col-1">刪除</th>
                                <th class="col-1">匯出</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td rowspan="2">權限管理</td>
                                <td>帳號管理</td>
                                <td>
                                    <div class="form-check p-0">
                                        <input class="form-check-input float-none mx-auto" type="checkbox" id="flexCheckDisabled" v-model="role_data.account.is_view" :disabled="$route.name === 'RoleView'">
                                    </div>
                                </td>
                                <td>
                                    <div class="form-check p-0">
                                        <input class="form-check-input float-none mx-auto" type="checkbox" id="flexCheckDisabled" v-model="role_data.account.is_create" :disabled="$route.name === 'RoleView'">
                                    </div>
                                </td>
                                <td>
                                    <div class="form-check p-0">
                                        <input class="form-check-input float-none mx-auto" type="checkbox" id="flexCheckDisabled" v-model="role_data.account.is_edit" :disabled="$route.name === 'RoleView'">
                                    </div>
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>群組管理</td>
                                <td>
                                    <div class="form-check p-0">
                                        <input class="form-check-input float-none mx-auto" type="checkbox" id="flexCheckDisabled" v-model="role_data.role_management.is_view" :disabled="$route.name === 'RoleView'">
                                    </div>
                                </td>
                                <td>
                                    <div class="form-check p-0">
                                        <input class="form-check-input float-none mx-auto" type="checkbox" id="flexCheckDisabled" v-model="role_data.role_management.is_create" :disabled="$route.name === 'RoleView'">
                                    </div>
                                </td>
                                <td>
                                    <div class="form-check p-0">
                                        <input class="form-check-input float-none mx-auto" type="checkbox" id="flexCheckDisabled" v-model="role_data.role_management.is_edit" :disabled="$route.name === 'RoleView'">
                                    </div>
                                </td>
                                <td>
                                    <div class="form-check p-0">
                                        <input class="form-check-input float-none mx-auto" type="checkbox" id="flexCheckDisabled" v-model="role_data.role_management.is_delete" :disabled="$route.name === 'RoleView'">
                                    </div>
                                </td>
                                <td></td>
                            </tr>

                            <tr>
                                <td rowspan="3">客服管理</td>
                                <td>進線管理</td>
                                <td>
                                    <div class="form-check p-0">
                                        <input class="form-check-input float-none mx-auto" type="checkbox" id="flexCheckDisabled" v-model="role_data.service_management.is_view" :disabled="$route.name === 'RoleView'">
                                    </div>
                                </td>
                                <td>
                                    <div class="form-check p-0">
                                        <input class="form-check-input float-none mx-auto" type="checkbox" id="flexCheckDisabled" v-model="role_data.service_management.is_create" :disabled="$route.name === 'RoleView'">
                                    </div>
                                </td>
                                <td>
                                    <div class="form-check p-0">
                                        <input class="form-check-input float-none mx-auto" type="checkbox" id="flexCheckDisabled" v-model="role_data.service_management.is_edit" :disabled="$route.name === 'RoleView'">
                                    </div>
                                </td>
                                <td></td>
                                <td>
                                    <div class="form-check p-0">
                                        <input class="form-check-input float-none mx-auto" type="checkbox" id="flexCheckDisabled" v-model="role_data.service_management.is_export" :disabled="$route.name === 'RoleView'">
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td>FAQ管理</td>
                                <td>
                                    <div class="form-check p-0">
                                        <input class="form-check-input float-none mx-auto" type="checkbox" id="flexCheckDisabled" v-model="role_data.service_faq_management.is_view" :disabled="$route.name === 'RoleView'">
                                    </div>
                                </td>
                                <td>
                                    <div class="form-check p-0">
                                        <input class="form-check-input float-none mx-auto" type="checkbox" id="flexCheckDisabled" v-model="role_data.service_faq_management.is_create" :disabled="$route.name === 'RoleView'">
                                    </div>
                                </td>
                                <td>
                                    <div class="form-check p-0">
                                        <input class="form-check-input float-none mx-auto" type="checkbox" id="flexCheckDisabled" v-model="role_data.service_faq_management.is_edit" :disabled="$route.name === 'RoleView'">
                                    </div>
                                </td>
                                <td></td>
                                <td>
                                    <div class="form-check p-0">
                                        <input class="form-check-input float-none mx-auto" type="checkbox" id="flexCheckDisabled" v-model="role_data.service_faq_management.is_export" :disabled="$route.name === 'RoleView'">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>績效管理</td>
                                <td>
                                    <div class="form-check p-0">
                                        <input class="form-check-input float-none mx-auto" type="checkbox" id="flexCheckDisabled" v-model="role_data.service_ranking.is_view" :disabled="$route.name === 'RoleView'">
                                    </div>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                    <div class="form-check p-0">
                                        <input class="form-check-input float-none mx-auto" type="checkbox" id="flexCheckDisabled" v-model="role_data.service_ranking.is_export" :disabled="$route.name === 'RoleView'">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td rowspan="2">銷售模組</td>
                                <td>交易紀錄查詢</td>
                                <td>
                                    <div class="form-check p-0">
                                        <input class="form-check-input float-none mx-auto" type="checkbox" id="flexCheckDisabled" v-model="role_data.sales_record.is_view" :disabled="$route.name === 'RoleView'">
                                    </div>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                    <div class="form-check p-0">
                                        <input class="form-check-input float-none mx-auto" type="checkbox" id="flexCheckDisabled" v-model="role_data.sales_record.is_export" :disabled="$route.name === 'RoleView'">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>銷售統計</td>
                                <td>
                                    <div class="form-check p-0">
                                        <input class="form-check-input float-none mx-auto" type="checkbox" id="flexCheckDisabled" v-model="role_data.sales_statistics.is_view" :disabled="$route.name === 'RoleView'">
                                    </div>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                    <div class="form-check p-0">
                                        <input class="form-check-input float-none mx-auto" type="checkbox" id="flexCheckDisabled" v-model="role_data.sales_statistics.is_export" :disabled="$route.name === 'RoleView'">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td rowspan="2">通路模組</td>
                                <td>通路管理</td>
                                <td>
                                    <div class="form-check p-0">
                                        <input class="form-check-input float-none mx-auto" type="checkbox" id="flexCheckDisabled" v-model="role_data.channel_management.is_view" :disabled="$route.name === 'RoleView'">
                                    </div>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>通路績效</td>
                                <td>
                                    <div class="form-check p-0">
                                        <input class="form-check-input float-none mx-auto" type="checkbox" id="flexCheckDisabled" v-model="role_data.channel_rank.is_view" :disabled="$route.name === 'RoleView'">
                                    </div>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-12 row mx-auto text-center">
                <div class="mb-3">
                    <div class="mx-auto">
                        <button type="submit" class="btn m-2 btn-primary" :disabled="button_disabled" v-if="$route.name !== 'RoleView'">送出</button>
                        <button type="button" class="btn m-2 btn-danger" v-on:click="go_back()">返回</button>
                    </div>
                </div>
            </div>
        </Form>
    </div>
</template>

<script>
import {
    get_role_detail,
    save_role_detail,
} from '../../api/role';
import { mapActions, mapGetters } from 'vuex';
import { Field, Form, ErrorMessage } from 'vee-validate';
import { role_rules } from '../../rules/admin/role';
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2';

export default {
    components: {
        Field,
        Form,
        ErrorMessage,
    },
    data() {
        return {
            role_data: {
                name: "",
                status: "",
            account: {
                code: "10001",
                is_view: false,
                is_create: false,
                is_edit: false,
                is_delete: false,
                is_export: false
            },
            role_management: {
                code: "10002",
                is_view: false,
                is_create: false,
                is_edit: false,
                is_delete: false,
                is_export: false
            },
            service_management: {
                code: "10101",
                is_view: true,
                is_create: false,
                is_edit: false,
                is_delete: false,
                is_export: false
            },
            service_faq_management: {
                code: "10102",
                is_view: true,
                is_create: false,
                is_edit: false,
                is_delete: false,
                is_export: false
            },
            service_ranking: {
                code: "10103",
                is_view: false,
                is_create: false,
                is_edit: false,
                is_delete: false,
                is_export: false
            },
            sales_record: {
                code: "10201",
                is_view: false,
                is_create: false,
                is_edit: false,
                is_delete: false,
                is_export: false
            },
            sales_statistics: {
                code: "10202",
                is_view: true,
                is_create: true,
                is_edit: true,
                is_delete: false,
                is_export: true
            },
            channel_management: {
                code: "10301",
                is_view: true,
                is_create: true,
                is_edit: true,
                is_delete: false,
                is_export: true
            },
            channel_rank: {
                code: "10302",
                is_view: true,
                is_create: false,
                is_edit: false,
                is_delete: false,
                is_export: true
            }
        },
            button_disabled: false,
            can_go_back: false,
        }
    },
    methods: {
        ...mapActions([
            'auth/UserLogout',
        ]),
        user_logout() {
            this['auth/UserLogout']().then(() => {
                this.$router.push({ name: 'Login' });
            }, () => {
                this.$router.push({ name: 'Login' });
            })
        },
        search() {
            get_role_detail({role_id: this.$route.params.id}).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else if (response.data.success === false && response.data.error_code === "1002") {
                    this.$router.push({ name: 'RoleManagement' });
                } else if (response.data.success === false && response.data.error_code === "1003") {
                    this.$router.push({ name: 'RoleManagement' });
                } else {
                    this.role_data = response.data.response_data;
                }
            })
        },
        save() {
            this.button_disabled = true;
            save_role_detail(this.role_data).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else {
                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: {
                            confirmButton: 'm-2 btn btn-primary',
                        },
                        buttonsStyling: false
                    })
                    swalWithBootstrapButtons.fire({
                        title: '提示訊息',
                        text: response.data.message,
                        icon: 'success',
                        showCloseButton: true,
                        allowOutsideClick: false,
                        confirmButtonText: '確認',
                    }).then((result) => {
                        if (result.isConfirmed || result.dismiss === "close") {
                            this.$router.push({ name: 'RoleManagement' });
                        }
                    })
                }
            }, error => {
                this.button_disabled = false;
                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                        cancelButton: 'm-2 btn btn-danger'
                    },
                    buttonsStyling: false
                })
                swalWithBootstrapButtons.fire({
                    title: '錯誤訊息',
                    text: error.response.data.message,
                    icon: 'error',
                    cancelButtonText: '關閉',
                    showCancelButton: true,
                    showConfirmButton: false,
                    showCloseButton: true,
                    allowOutsideClick: false,
                })
            });
        },
        on_invalid_submit() {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    cancelButton: 'm-2 btn btn-danger'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: '錯誤訊息',
                text: '尚有欄位未填寫或是填寫錯誤！',
                icon: 'error',
                cancelButtonText: '關閉',
                showCancelButton: true,
                showConfirmButton: false,
                showCloseButton: true,
                allowOutsideClick: false,
            });
        },
        go_back() {
            if (this.can_go_back === true) {
                this.$router.back();
            } else {
                this.$router.push({name: "RoleManagement"});
            }
        }
    },
    created() {
        window.scrollTo({top: 0, behavior: "smooth"});
        const router = useRouter();
        if (router.options.history.state.back !== null) {
            this.can_go_back = true;
        }
        if (this.$route.params.id !== undefined) {
            this.search();
        }
    },
    computed: {
        schema() {
            return role_rules;
        },
        ...mapGetters({
            role_list: 'role_list'
        }),
    }
};
</script>
